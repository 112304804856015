import styled from 'styled-components';
import { deviceLessThan } from '../../styles/breakpoints';

export const OpinionContainer = styled.div`
    width:75%;
    margin:0 auto;
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 77px 300px;
        position: absolute;
        bottom: -150px;
        right: 0px;
        height: 300px;
        width: 77px;
        z-index: -1;
    };
    @media ${deviceLessThan.laptop}{
        width:95%;
    };
    @media ${deviceLessThan.tablet}{
        &::before {
            content: "";
            width: 219px;
            border-radius: 50%;
            height: 219px;
            background-color: #eef8fd;
            position: absolute;
            top: 0px;
            left: -70px;
            z-index:-1;
        }
    }
`;

export const MediaCenterPageLink = styled.div`
    color:${props => props.color ? props.theme.colors[props.color] : props.theme.colors.CHQBOOK_BLUE};
    font-size:16px;
    padding:5px 15px;
    border:1.5px solid #f7f7f7;
    cursor:pointer;
    width:100%;
    text-align:center;
    & a{
        color:#19447a;
    }
`
export const OpinionCardContainer = styled.div`
    border-radius: 6px;
    box-shadow: 0 0 13px 1px rgba(0,0,0,0.1);
    padding: 16px;
    text-align:${props => props.align};
    background: white;
    height: 100%;
    padding:15px 15px;
    flex-direction: column;
    display: flex;
    @media ${deviceLessThan.tablet}{
        text-align:unset;
        box-shadow:0 0 16px 0 rgba(0, 0, 0, 0.1);
        padding: 10px;
    }
`;

export const ViewImageHeader = styled.div`
    display:flex;
    background-color:#2b2b2b;
    width:100%;
    color:white;
    padding:10px 55px;
    font-weight: 600;
    @media ${deviceLessThan.tablet}{
        padding:10px 23px;
    }
`;

export const ViewImageBody = styled.div`
    background-color:#444;
    padding:25px 55px;
    @media ${deviceLessThan.tablet}{
        padding:23px;
    }
`;

export const ViewImageTitle = styled.div`
    flex:1;
    font-size:16px;
    padding:6px 0px
`;

export const OverlayContainer = styled.div`
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`;

export const OpinionHeading = styled.div`
    margin: 85px 0px 0px;
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 230px 280px;
        position: absolute;
        bottom: -460px;
        left: -35px;
        height: 280px;
        width: 230px;
        z-index: -1;
    };
    @media ${deviceLessThan.laptop}{
        text-align:left;
        margin: 50px 0px 0px;
    }
    @media ${deviceLessThan.tablet}{
        text-align:center;
        margin: 25px 0px 0px;
    }
`;

export const OpinionPostContainer = styled.div`
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding:0px 0px 50px;
    &::before{
        content: "";
        background:url(${props => props.backgroundBefore}) no-repeat;
        background-size: 301px 136px;
        position: absolute;
        top: 0px;
        right: 20%;
        height: 136px;
        width: 301px;
        z-index: -1;
    };
    &::after{
        content: "";
        background:url(${props => props.backgroundAfter}) no-repeat;
        background-size: 77px 300px;
        position: absolute;
        bottom: -150px;
        right: 0px;
        height: 300px;
        width: 77px;
        z-index: -1;
    };
    @media ${deviceLessThan.tablet}{
        padding:30px 0px;
        display: block;
        &::before{
            content: "";
            background:unset;
        };
        &::after{
            content: "";
            background:unset;
        };
    }
`;

export const OpinionCardWrapper = styled.div`
    flex: 0 50%;
    box-sizing:border-box;
    width: 50%;
    height: auto;
    position:relative;
    &:nth-of-type(2n+1){
        padding:0px 13px 26px 0px;
    };
    &:nth-of-type(2n){
        padding: 0px 0px 26px 13px;
    };
    @media ${deviceLessThan.tablet}{
        padding:${props => props.sPadding};
        text-align:left;
        margin:0 auto;
        width: 95%;
        padding:0px 0px 20px !important;
        &:last-child{
            padding:0px 0px 40px !important;
        }
    }
`;