import React from 'react';
import Opinion from '../components/Opinion'
import { graphql } from 'gatsby';
import { modifyContent } from '../utils/helpers';

const OpinionPage = props => {
    let content = modifyContent(props.data);
    const title = props.data.content?.nodes[0].meta_title;
    const description = props.data.content?.nodes[0].meta_description;

    const opinions = props.data.allGhostPost.edges;

    return (
        <Opinion
            data={content.content}
            opinions={opinions}
            title={title}
            description={description}
            location={props.location}
            pageContext={props.pageContext}
        />
    )
}

export default OpinionPage;

export const pageQuery = graphql`
    query GhostOpinionTagQuery($limit: Int!, $skip: Int!) {
        ghostTag(slug: { eq: "opinion" }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: "opinion"}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }

        content: allGhostPage(
                filter: {
                    tags: { elemMatch: { name: { eq: "#media-center-opinion"}}},
                }
            ) {
                nodes {
                    plaintext
                    meta_title
                    meta_description
                }
            }
}
`
