import React, { Fragment, useState, useEffect } from 'react';
import Layout from '../common/Layout';
import Modal from '../common/Modal/index';
import { ModalContainer, ModalWrapper } from '../common/Modal/Component';
import MetaData from '../common/meta/MetaData';
import { OpinionContainer, OpinionHeading, OpinionPostContainer, MediaCenterPageLink } from './Component';
import Heading from '../common/ui/Heading';
import CutomButton from '../common/ui/CutomButton'
import Text from '../common/ui/Text';
import { ImageContainer, Image } from '../common/ui/Image';
import CustomLink from '../common/ui/CustomLink';
import Posts from '../BlogIndex/Posts';
import { opinionRouteBaseUrl } from '../../constants/strings'

const Opinion = ({ title, description, keywords, location, opinions, data, pageContext }) => {

    const [toggleModal, setToggleModal] = useState(false);
    const [top, setTop] = useState(null);
    const [left, setLeft] = useState(null);

    useEffect(() => {
        if (toggleModal) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [toggleModal]);

    const modalHandler = (name, e) => {
        e.stopPropagation();
        if (e.target.getAttribute("class") && e.target.getAttribute("class").split(" ").includes("Back") === false) {
            setToggleModal(!toggleModal);
            if (toggleModal == false) {
                let element = document.getElementById(name).getBoundingClientRect();
                setTop(element.top + 'px');
                setLeft(element.left + 'px');
            }
        }
    }

    return (<Fragment>
        <MetaData
            data={{}}
            title={title}
            description={description}
            keywords={keywords}
            location={location}
            type="website"
        />
        <Layout>
            <OpinionContainer
                backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png"
            >
                <OpinionHeading
                    backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575403/chqbookWeb/AboutUs/hexagon-no-fill_3x.png"
                >
                    <CutomButton
                        minwidth="max-content"
                        swidth="116px"
                        width="131px"
                        backgroundcolor="WHITE"
                        color="CHQBOOK_BLUE"
                        weight="bold"
                        opacity="0.9"
                        size="xxl"
                        border="1px solid rgba(20, 70, 125, 0.2)"
                        hovercolor="primary"
                        boxshadow="0 4px 8px 0 rgba(0, 0, 0, 0.05)"
                        hoverbackground="WHITE"
                        smargin="0 auto"
                        padding="2px 12px 1px"
                        letterspacing="0.2px"
                        borderradius="6px"
                        onClick={modalHandler.bind(null, "newModal")}
                        id="newModal"
                    >
                        {data.sectionName}&nbsp;
                    <Image
                            src={`https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg`}
                            width="10px"
                        />
                    </CutomButton>
                    <Modal
                        show={toggleModal}
                        modalClosed={modalHandler.bind(null, null)}
                    >
                        <ModalContainer
                            top={top}
                            left={left}
                            sTop="111px"
                            sLeft="50%"
                            sTransform="translate(-50%, 0%)"
                            transform="translate(0px, 0px)"
                            className="Back"
                        >
                            <CutomButton
                                width="max-content"
                                backgroundcolor="WHITE"
                                color="CHQBOOK_BLUE"
                                size="xxl"
                                weight="bold"
                                hoverbackground="WHITE"
                                border="none"
                                hovercolor="primary"
                                onClick={modalHandler.bind(null, null)}
                                padding="2px 10px"
                                height="auto"
                            >
                                {data.sectionName}&nbsp;&nbsp;
                                    <Image
                                    src={`https://res.cloudinary.com/chqbook/image/upload/q_auto/v1599743378/chqbookWeb/0-5kb/down-arrow.svg`}
                                    width="12px"
                                    style={{ transform: "rotate(180deg)" }}
                                />
                            </CutomButton>
                            <ModalWrapper className="Back">
                                {
                                    data.modalLink.map((el, i) => (
                                        <CustomLink to={el.link} key={i}>
                                            <MediaCenterPageLink color="CHQBOOK_BLUE">
                                                {el.text}
                                            </MediaCenterPageLink>
                                        </CustomLink>
                                    )
                                    )
                                }
                            </ModalWrapper>
                        </ModalContainer>
                    </Modal>
                    <Heading
                        color='MEDIUM_BLACK'
                        maxWidth='max-content'
                        size='xl'
                        weight='normal'
                        lineHeight='1.37'
                        margin="25px 0px"
                        sMargin="20px auto"
                    >
                        {data.heading}
                        <ImageContainer
                            width="210px"
                            sWidth="180px"
                            margin="-20px 0px 0px 0px"
                            sMargin="-5px 0px 0px 0px"
                            xsMargin="-5px auto 0px auto"
                            sdisplay="block"
                        >
                            <Image
                                src={`https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1602189754/chqbookWeb/0-5kb/red-underline.png`}
                            />
                        </ImageContainer>
                    </Heading>
                    <Text
                        color='MEDIUM_BLACK'
                        opacity='0.7'
                        size='xl'
                        weight='medium'
                        lineHeight='1.7'
                        letterSpacing='-0.33px'
                        width="max-content"
                        sMargin="0px auto"
                        padding="0px 5vw 0px 0px"
                        sPadding="0px"
                    >
                        {data.description}
                    </Text>
                </OpinionHeading>
                <OpinionPostContainer
                    backgroundAfter="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575448/chqbookWeb/AboutUs/dot-pattern-hero_3x.png"
                    backgroundBefore="https://res.cloudinary.com/chqbook/image/upload/q_auto,f_auto/v1603575434/chqbookWeb/AboutUs/dot-pattern_3x.png"
                >
                    {
                        <Posts
                            containerWidth='100%'
                            posts={opinions}
                            pageContext={pageContext}
                            indexRoute={`${opinionRouteBaseUrl}/${pageContext.slug}`}
                            gridDesktopColumns='lfr lfr'
                            hideFooter={true}
                            isOpinion={true}
                        />}
                </OpinionPostContainer>
            </OpinionContainer>
        </Layout>
    </Fragment>)
}

export default Opinion;
